import React from 'react';

const HitUnit = ({hit, hitIndex, localization}) => (
    <div className="hw-block hw-block--full hw-block--mt-medium-3">
        <div className="hw-badge hw-badge--smaller hw-badge--uppercase">{localization.unit.categoryLabel}</div>
        <div className="hw-block hw-block--full hw-block--mt-small-2" id={"hit-" + hitIndex}>
            <h2 className="hw-h3">{hit.heading}</h2>
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-2" id={"hitContent-" + hitIndex}>
            {hit.closingHours?.map(item => ( <p>{item.deadline + ' ' + item.period + ' ' + item.time }</p>))}
            <p>{localization.unit.openingHoursLabel + ': ' + hit.openingHoursDesktop}</p>
            <p>{hit.visitingAddress ? hit.visitingAddress.csv : ''}</p>
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <a className="hw-link hw-link--small" href={hit.absoluteUrl} aria-describedby={`hit-${hitIndex} hitContent-${hitIndex}`}>{localization.unit.linkLabel}</a>
        </div>
    </div>
);

export default HitUnit;
