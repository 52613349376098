import React, { useRef, useState } from 'react'
import { Button, Input, HStack } from '@postenbring/hedwig-react'
import { loadScript } from '/lib/load-script'

export const ChatbotInputField = ({
    placeholder,
    label,
    sendButtonText,
    inputAriaLabel,
    errorMessage
}) => {
    const inputRef = useRef(null)
    const [hasError, setHasError] = useState(false)

    const messageIsValid = () => {
        const message = inputRef.current.value.trim()
        console.log(message)
        return message.length >= 2
    }

    const handleSend = () => {
        if (messageIsValid()) {
            sendChatbotMessage(inputRef.current.value.trim())
            inputRef.current.value = ''
            setHasError(false)
        } else {
            setHasError(true)
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            handleSend()
            return
        }

        if (messageIsValid()) {
            setHasError(false)
        }
    }

    const sendChatbotMessage = async (message) => {
        /**
         * The variable 'kindlyScriptAttribute' and the function
         * 'callClickEventForChatbotButton' are implicitly loaded
         * by kp-app-posten and kp-app-bring on each page where
         * the chatbot is enabled.
         * https://github.com/bring/kp-app-posten/blob/master/src/main/resources/assets/js/init-kindly-chatbot.js
         */
        loadScript(
            'https://chat.kindlycdn.com/kindly-chat.js',
            'kindly-chat',
            kindlyScriptAttributes
        )
            .then(() => {
                let retryCount = 0
                const intervalId = setInterval(() => {
                    if (retryCount > 20) {
                        clearInterval(intervalId)
                        console.error('Failed to load chatbot script')
                    }
                    retryCount += 1
                    if (window.kindlyChat) {
                        window.kindlyChat.openChat()
                        window.kindlyChat.sendMessage(message, {
                            sender: 'user'
                        })
                        clearInterval(intervalId)
                    }
                }, 200)
            })
            .catch(function (error) {
                console.error('Error loading chatbot script:', error)
            })
    }

    return (
        <HStack gap='4' align='self-start' id='kp-chatbot-input-field'>
            <Input
                type='text'
                ref={inputRef}
                errorMessage={hasError ? errorMessage : ''}
                aria-label={inputAriaLabel ?? 'Type your question here'}
                label={label ?? placeholder}
                labelProps={{
                    style: { display: 'none' },
                    htmlFor: 'chat-input-text'
                }}
                id='chat-input-text'
                placeholder={placeholder}
                onKeyUp={handleKeyUp}
            />
            <Button
                onClick={handleSend}
                variant='primary'
                size='large'
                style={{ whiteSpace: 'nowrap' }}
            >
                {sendButtonText ?? 'Send'}
            </Button>
        </HStack>
    )
}
