import { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import _ from 'promise-polyfill';
import 'whatwg-fetch';
import { Tabs, Text, Grid, ShowMoreButton, Container, StyledHtml } from "@postenbring/hedwig-react";

import Loader from './Loader.jsx';
import Hit from './Hit.jsx';

import './search.scss';

export const Search = ({ settings, q, localization, image, noHitsHtml }) => {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [visibleCount, setVisibleCount] = useState(settings.startCount || 10);
    const [searchText, setSearchText] = useState(q || '');
    const [counts, setCounts] = useState({});
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const firstRenderFinished = useRef(false);
    const [hits, setHits] = useState([]);
    const [offset, setOffset] = useState(0);
    const [categories, setCategories] = useState([]);

    let cache = [];

    const search = (term, category) => {
        setIsLoading(true);
        const l = window.location;
        const path = `${l.origin}${l.pathname}?q=${term}${l.hash}`;
        const stateObj = { searchText: term };
        history.replaceState(stateObj, searchText, path);

        handleSearch(term, 0, settings.startCount + settings.loadMoreCount, category);
    };

    const searchDebounced = useCallback(debounce(search, 500), []);

    useEffect(() => {
        search(searchText, selectedCategory);
        if (hedwig) {
            hedwig.HWSearch();
        }
    }, []);

    useEffect(() => {
        if (firstRenderFinished.current && searchText.length > 3) {
            searchDebounced(searchText, selectedCategory);
        } else if (!firstRenderFinished.current) {
            firstRenderFinished.current = true;
        }
    }, [searchText]);

    const handleShowMore = () => {
        setVisibleCount(visibleCount + settings.loadMoreCount);
        setOffset(offset + settings.loadMoreCount);
        handleSearch(searchText, offset, settings.loadMoreCount, selectedCategory);
    };

    const handleSearch = (searchTerm, offset, count, category) => {
        const searchUrl = `${settings.searchServiceUrl}?q=${searchTerm}&type=${category}&start=${offset}&count=${count}`;
        const cache_ = _.resolve(cache[searchUrl]);
        cache_
            .then((cacheResult) => {
                if (cacheResult) {
                    return cacheResult;
                } else {
                    return fetch(searchUrl).then((fetchedData) => {
                        cache[searchUrl] = fetchedData.json();
                        return _.resolve(cache[searchUrl]);
                    });
                }
            })
            .then((result) => {
                if (result.request.params.q === searchTerm) {
                    setHits(offset > 0 ? [...hits, ...result.hits] : result.hits);
                    setCounts(result.counts);
                    setOffset(offset + count);
                    if (result.categories.includes("content")) {
                        const indexValue = result.categories.indexOf("content");
                        const content = result.categories.splice(indexValue, 1).toString();
                        result.categories.unshift(content);
                    }
                    setCategories(result.categories);
                    if (hedwig) {
                        hedwig.HWCurtain();
                    }
                }
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => setIsLoading(false));
    };

    const setCategory = (category) => {
        if (category === selectedCategory) {
            return;
        }
        setSelectedCategory(category);
        setVisibleCount(settings.startCount);
        handleSearch(searchText, 0, settings.startCount + settings.loadMoreCount, category);
    };

    const getCategoryLabel = (category) => {
        switch (category) {
            case 'others':
                return localization.others.categoryLabel
            case 'content':
                return localization.categoryContentLabel
            case 'unit':
                return localization.unit.categoryLabel
            case 'article':
                return localization.article.categoryLabel
            case 'postalCode':
                return localization.postalCode.categoryLabel
            case 'address':
                return localization.address.categoryLabel
            case 'all':
                return localization.categoryAllLabel
            case 'rutiner':
                return localization.rutiner.categoryLabel
        }
    }

    const tabItems = () =>
        ['all', ...categories].map((category) => ({
            id: category,
            label: (
                <>
                    {getCategoryLabel(category)}{' '}<Text as="span" variant="body-small">({counts[category]})</Text>
                </>
            )
        }));

    const shouldDisplayShowMoreButton = hits.length > 0 && counts[selectedCategory] > visibleCount && !isLoading;

    const HeroImage = ({ image }) =>
        image && image.large ? (
            <div className='hw-hero__image' style={{ backgroundImage: `url(${image.large})` }}></div>
        ) : null;

    return (
        <>
            <div className='hw-hero hw-hero--small'>
                <HeroImage image={image} />
                <div className='hw-hero__overlay'>
                    <Container style={{ height: '100%' }}>
                        <div className='hw-hero__content hw-hero__content--bottom'>
                            <Grid gap="12-16" span={{ initial: 12, small: 8 }}>
                                <Grid.Item span={{ large: 8, small: 12 }}>
                                    <div className='hds-mb-32'>
                                        <h1 className='hw-h1 hw-color-white'>
                                            {localization.title}
                                        </h1>
                                    </div>
                                    <div className='hds-mb-32'>
                                        <div className='hw-search' data-hw-search data-hw-search-placeholders=''>
                                            <div className='hw-search__inner'>
                                                <form id='react-search-form' method='get' role='search' aria-label={localization.searchFieldPlaceholder}>
                                                    <input
                                                        data-hw-search-input
                                                        defaultValue={q}
                                                        name='q'
                                                        className='hw-search__input'
                                                        type='text'
                                                        aria-label={localization.searchFieldPlaceholder}
                                                        placeholder={localization.searchFieldPlaceholder}
                                                        autoComplete='off'
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                    />
                                                </form>
                                                <i className='far fa-search hw-search__icon'></i>
                                                <button className='hw-search__button-inside' data-hw-search-reset>
                                                    <i className='fas fa-times-circle'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid.Item>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </div>


            <Container id='react-search-results'>
                <div className='hds-mb-32 hds-mt-64'>
                    <h2 className='hw-h2'>{localization.searchResultLabel}</h2>
                </div>
                <div className='hds-mb-32 hds-mt-40'>
                    <Tabs defaultTab={selectedCategory}>
                        <Tabs.List>
                            {tabItems().map(({ id, label }) => (
                                <Tabs.Tab key={id} tabId={id} onClick={() => setCategory(id)}>
                                    {label}
                                </Tabs.Tab>
                            ))}
                        </Tabs.List>
                        <div>
                            {tabItems().map(({ id }) => (
                                <Tabs.Content key={id} forTabId={id}>
                                    <Grid gap="12-16" span={{ initial: 12, small: 8 }}>
                                        <Grid.Item span={{ large: 8, small: 12 }}>
                                            {isLoading ? (
                                                <div className='hds-mt-24 hds-mb-24'>
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <>
                                                    {hits
                                                        .slice(0, visibleCount)
                                                        .map((hit, index) => (
                                                            <Hit hit={hit} key={index} hitIndex={index} localization={localization} />
                                                        ))}
                                                    {shouldDisplayShowMoreButton && (
                                                        <div className='hds-mt-24 hds-mb-24'>

                                                            <ShowMoreButton
                                                                onClick={handleShowMore}
                                                                text={localization.loadMoreLabel}
                                                            />


                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {hits.length === 0 && !isLoading && (
                                                <StyledHtml dangerouslySetInnerHTML={{ __html: noHitsHtml }}></StyledHtml>
                                            )}
                                        </Grid.Item>
                                    </Grid>
                                </Tabs.Content>
                            ))}
                        </div>
                    </Tabs>
                </div>
            </Container>
        </>
    );
};
