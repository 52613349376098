import { Accordion as HedwigAccordion } from "@postenbring/hedwig-react";

export const Accordion = ({ accordionItems }) => {
    return (
        <div className="hds-mb-24">
            <HedwigAccordion>
                {accordionItems.map((item, index) => (
                    <HedwigAccordion.Item defaultOpen={item.isExpanded} key={index}>
                        <HedwigAccordion.Header>{item.text}</HedwigAccordion.Header>
                        <HedwigAccordion.Content>
                            <div className="hw-wysiwyg" dangerouslySetInnerHTML={{ __html: item.content }} />
                        </HedwigAccordion.Content>
                    </HedwigAccordion.Item>
                ))}
            </HedwigAccordion>
        </div>
    );
}