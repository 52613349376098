import React from 'react';

const PickupPoint = ({pickupPoint, localization}) => (
    <>
        <p><span className="hw-color-primary-dark">{localization.pickupPlace}:</span> {pickupPoint.heading}</p>
        <p>{pickupPoint.openingHours + ', ' + pickupPoint.visitingAddress && pickupPoint.visitingAddress.csv}</p>
    </>
)


export default PickupPoint;
