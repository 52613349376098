import React from 'react'
import { HStack, Spinner } from "@postenbring/hedwig-react";

const Loader = () => (
    <HStack gap="24" wrap align="end">
        <Spinner delay />
  </HStack>
)

export default Loader
