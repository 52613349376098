import React from 'react';

const HitPostalCode = ({hit, hitIndex, localization}) => (
    <div className="hw-block hw-block--full hw-block--mt-medium-3">
        <div className="hw-badge hw-badge--smaller hw-badge--uppercase">{localization.postalCode.categoryLabel}</div>
        <div className="hw-block hw-block--full hw-block--mt-small-2" id={"hit-" + hitIndex}>
            <h2 className="hw-h3">{hit.heading}</h2>
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <i className={(hit.streetAddresses ? 'hw-color-green' : 'hw-color-red') + " fas fa-times-circle"}></i> {localization.postalCode.streetAddressLabel}
        </div>
        <div className="hw-block hw-block--full">
            <i className={(hit.poBox ? 'hw-color-green' : 'hw-color-red') + " fas fa-check-circle"}></i> {localization.postalCode.poBoxLabel}
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <a className="hw-link hw-link--small" href={hit.absoluteUrl} aria-describedby={"hit-" + hitIndex}>{localization.postalCode.linkLabel}</a>
        </div>
    </div>
);

export default HitPostalCode;
