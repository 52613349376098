import React from "react";
import HitContent from './HitContent.jsx';
import HitPostalCode from './HitPostalCode.jsx';
import HitAddress from './HitAddress.jsx';
import HitUnit from './HitUnit.jsx';
import HitDefault from './HitDefault.jsx';
import HitArticle from './HitArticle.jsx';

export default ({ hit = {}, hitIndex, localization}) => {
    switch(hit._category){
        case 'content': return <HitContent hit={hit} hitIndex={hitIndex} localization={localization} />;
        case 'postalCode': return <HitPostalCode hit={hit} hitIndex={hitIndex} localization={localization} />;
        case 'address': return <HitAddress hit={hit} hitIndex={hitIndex} localization={localization} />;
        case 'unit': return <HitUnit hit={hit} hitIndex={hitIndex} localization={localization} />;
        case 'article': return <HitArticle hit={hit} hitIndex={hitIndex} localization={localization} />;
        case 'others': return <HitArticle hit={hit} hitIndex={hitIndex} localization={localization} />;
        case 'rutiner': return <HitArticle hit={hit} hitIndex={hitIndex} localization={localization} />;
        default: return <HitDefault hit={hit} hitIndex={hitIndex} localization={localization} />;
    }
};
