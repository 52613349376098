import React from 'react';
import PickupPoint from './PickupPoint.jsx';

const HitAddress = ({hit, hitIndex, localization}) => (
    <div className="hw-block hw-block--full hw-block--mt-medium-3">
        <div className="hw-badge hw-badge--smaller hw-badge--uppercase">{localization.address.categoryLabel}</div>
        <div className="hw-block hw-block--full hw-block--mt-small-2" id={"hit-" + hitIndex}>
            <div className="hw-curtain">
                <button className="hw-curtain__trigger">
                    <h2 className="hw-h3">{hit.heading}</h2>
                    <div className="hw-curtain__arrow"></div>
                </button>
                <div className="hw-curtain__content">
                    <div class="hw-block hw-block--full hw-block--pt-small-3 hw-block--pb-small-3">
                        <span>{localization.address.houseNumber}: </span>
                        {hit.house_number_letter_list ? hit.house_number_letter_list.map((number, index) => <React.Fragment key={index}>{!!index && ", "}{number}</React.Fragment>) : ''}
                    </div>
                </div>
            </div>
            {hit.pickupPoint && (
                <div className="hw-block hw-block--full hw-block--mt-small-2">
                    <PickupPoint pickupPoint={hit.pickupPoint} localization={localization}/>
                </div>
            )}
        </div>
        {hit.pickupPoint && (
            <div className="hw-block hw-block--full hw-block--mt-small-2">
                <a className="hw-link hw-link--small" href={hit.absoluteUrl} aria-describedby={"hit-" + hitIndex}>{localization.address.linkLabel}</a>
            </div>
        )}
    </div>
);

export default HitAddress;
