import React from 'react';

const HitContentRegular = ({hit, hitIndex, localization}) => (
    <div className="hw-block hw-block--full hw-block--mt-medium-3">
        <div className="hw-badge hw-badge--smaller hw-badge--uppercase">{localization.categoryContentLabel}</div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <h2 className="hw-h3">{hit.heading}</h2>
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <p id={"hitContent-" + hitIndex}><div dangerouslySetInnerHTML={{__html: hit.preface}}/></p>
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <a className="hw-link hw-link--small" href={hit.absoluteUrl} aria-label={hit.heading} aria-describedby={"hitContent-" + hitIndex}>{hit.absoluteUrl}</a>
        </div>
    </div>
);

const HitContentMedia = ({ hit, hitIndex, localization }) => (
    <div className="hw-block hw-block--full hw-block--mt-medium-3">
        <div className="hw-badge hw-badge--smaller hw-badge--uppercase">{localization.mediaDocumentLabel}</div>
        <div className="hw-block hw-block--full hw-block--mt-small-2">
            <h2 className="hw-h3" id={"hit-" + hitIndex}>{hit.heading}</h2>
        </div>
        <div className="hw-block hw-block--full hw-block--mt-small-3">
            <a className="hw-link hw-link--with-icon hw-link--dark hw-link--small" href={hit.absoluteUrl} aria-describedby={"hit-" + hitIndex} target="_blank">
                <i className="fas fa-desktop"></i> {localization.openNewWindow}
            </a>
        </div>
        <div className="hw-block hw-block--full">
            <a className="hw-link hw-link--with-icon hw-link--dark hw-link--small" download={hit.filename} href={hit.serverDownloadUrl} aria-describedby={"hit-" + hitIndex}>
                <i className="fas fa-cloud-download"></i> {localization.download}
            </a>
        </div>
    </div>
);


const HitContent = ({hit, hitIndex, localization}) => {
    return hit._type == 'media:document' ?
        <HitContentMedia localization={localization} hit={hit} hitIndex={hitIndex}/> :
        <HitContentRegular localization={localization} hit={hit} hitIndex={hitIndex}/>;
};

export default HitContent;
